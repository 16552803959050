import React from 'react';
import PropTypes from 'prop-types';
import TopNav from './TopNav';
import Footer from './Footer';

const Layout = props => {
    return (
        <div className='layout'>
            <TopNav />
            {props.children}
            <Footer />
        </div>
    );
};

Layout.propTypes = {
    children: PropTypes.object
};

Layout.defaultProps = {

};

export default Layout;
