import React from 'react';
import ReactDOM from 'react-dom/client';
import { ApolloProvider, ApolloClient, InMemoryCache, HttpLink, ApolloLink, concat } from '@apollo/client';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import { HelmetProvider } from 'react-helmet-async';

const uri = '/api/graphql';

const httpLink = new HttpLink({ uri });

const authMiddleware = new ApolloLink((operation, forward) => {
    // const token = localStorage.getItem('token');

    console.log({ operation });

    const headers = {};

    // if(operation.operationName === "login"){
    // headers = {}
    // } else {
    //   headers = token ? {'X-API-KEY': token,}:{}
    // }

    operation.setContext({
        headers
    });

    return forward(operation);
});

const defaultOptions = {
    watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore'
    },
    query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all'
    }
};

const client = new ApolloClient({
    link: concat(authMiddleware, httpLink),
    cache: new InMemoryCache(),
    defaultOptions
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <ApolloProvider client={client}>
            <HelmetProvider>
                <App />
            </HelmetProvider>
        </ApolloProvider>
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
