import React from 'react';
// import PropTypes from 'prop-types';
import { Element } from 'react-scroll';
import { Helmet } from 'react-helmet-async';
import LogoTile from '../home/LogoTile';
import NewsTile from '../home/NewsTile';
import PartnersTile from '../home/PartnersTile';
import AboutTile from '../home/AboutTile';

export const HomeContext = React.createContext();

const Home = props => {
    return (
        <HomeContext.Provider value={{ }}>
            <Element name='top'>
                <Helmet><title>StudioLab</title></Helmet>
                <LogoTile />
                <AboutTile />
                <NewsTile />
                <PartnersTile />
            </Element>
        </HomeContext.Provider>
    );
};

Home.propTypes = {

};

export default Home;
