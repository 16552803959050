import React, { useContext } from 'react';
import { Element } from 'react-scroll';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useQuery } from '@apollo/client';
import { GET_ARTICLES } from '../../queries';
import { Heading } from '../modules/Typography';
import { Container, Row } from '../modules/Grid';
import { WidthContext } from '../../contexts/WidthContextProvider';
import Carousel from './Carousel';

const NewsTile = props => {
    const { isLarge } = useContext(WidthContext);
    const { data, loading } = useQuery(GET_ARTICLES);
    const number = isLarge ? 2 : 3;
    if (loading) {
        return <div />;
    }

    return (
        <div className='NewsTile flex column align-center'>
            <Element name='news' className='scroll-element' />
            <Heading className='NewsTile__title' number={number}>Latest News</Heading>
            <div className='NewsTile__container'>
                <Row>
                    <Carousel articles={data.articles.data} />
                </Row>
            </div>
        </div>
    );
};

export default NewsTile;
