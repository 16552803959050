import { gql } from '@apollo/client';

export const GET_ARTICLES = gql`
  query articles {
    articles (sort: "publish_date:desc") {
      data { 
        attributes {
          headline 
          url 
          image {
            data {
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;
