import React, { useContext } from 'react';
import { Element } from 'react-scroll';
import natureRoom from '../../images/NatureRoom2.jpg';
import { Heading, Subtitle } from '../modules/Typography';
import { Container, Row, Col } from '../modules/Grid';
import { WidthContext } from '../../contexts/WidthContextProvider';

const AboutTile = props => {
    const { isMobile, size } = useContext(WidthContext);
    let number = 2;
    if (size === 'xs') {
        number = 4;
    } else if (isMobile || size === 'md' || size === 'lg') {
        number = 3;
    }
    return (
        <div className='Tile AboutTile'>
            <Element className='scroll-element' name='about' />
            <Container>
                <Row>
                    <Col md={12} className='AboutTile__title__column'>
                        <Heading className='AboutTile__title' number={number}>StudioLAB is The Walt Disney Studios' advanced development division for innovation in creative technologies.</Heading>
                    </Col>
                </Row>
                <Row>
                    <Col className='AboutTile__column'>
                        <img className='AboutTile__image' src={natureRoom} />
                        <div className='AboutTile__text__container flex column align-center'>
                            <Subtitle className='AboutTile__text' number={size === 'lg' ? 1 : 3}>StudioLAB is a place and program dedicated to storytelling via new technology located in the heart of the historic Walt Disney Studios lot.</Subtitle>
                            <Subtitle className='AboutTile__text' number={size === 'lg' ? 1 : 3}>Our mission is to drive innovation in support of the creative process and we are lucky enough to work with the top creative teams
                                and technology companies including Accenture, HPE, Microsoft, LG Display, Salesforce and T-Mobile. StudioLAB is led by a team of industry veterans with diverse backgrounds
                                that represent decades of software engineering, film production, theme park design and gaming development experience.
                            </Subtitle>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default AboutTile;
