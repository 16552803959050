import { gql } from '@apollo/client';

export const GET_HOME_PAGE = gql`
  query homepage {
    homepage {
      data { 
        attributes {
          introVideo {
            data { 
              attributes {
                url
              }
            }
          }
        }
      }
    }
  }
`;
