import React, { useContext } from 'react';
import { Element } from 'react-scroll';
import { Caption, Subtitle, Body } from '../modules/Typography';
import { WidthContext } from '../../contexts/WidthContextProvider';
import linkedIn from '../../images/linkedIn.png';
import twitter from '../../images/twitter.png';

const Footer = props => {
    const { isMobile, size } = useContext(WidthContext);
    const number = isMobile ? 1 : 3;
    const LinkText = isMobile ? Caption : Body;
    return (
        <Element name='contact' className='Footer'>
            <div className={`Footer__content flex align-flex-start ${size === 'xs' ? 'column' : ''}`}>
                <div className='Footer__left flex flex-start align-flex-start'>
                    <div className='flex column space-between Footer__content__column first'>
                        <div className='flex column flex-start'>
                            <Subtitle className='Footer__content__header' heavy number={number}>Follow us:</Subtitle>
                            <div className='Footer__icon__container flex'>
                                <a target='_blank' rel='noreferrer' href='https://twitter.com/DisneyStudioLAB'>
                                    <img className='Footer__icon linked-in' src={twitter} />
                                </a>
                                <a target='_blank' rel='noreferrer' href="https://www.linkedin.com/showcase/disney's-studiolab">
                                    <img className='Footer__icon linked-in' src={linkedIn} />
                                </a>
                            </div>
                        </div>
                        <Caption number={isMobile ? 2 : 1}>© Disney, All Rights Reserved</Caption>
                    </div>
                    <div className='flex column space-between Footer__content__column'>
                        <div className='flex column flex-start'>
                            <Subtitle className='Footer__content__header' heavy number={number}>StudioLAB</Subtitle>
                            <Subtitle className='Footer__content__text' number={number}>Walt Disney Studios</Subtitle>
                            <Subtitle className='Footer__content__text' number={number}>500 S Buena Vista St.</Subtitle>
                            <Subtitle className='Footer__content__text' number={number}>Burbank, CA</Subtitle>
                        </div>
                    </div>
                </div>
                <div className={`Footer__right flex flex-start align-flex-end ${size === 'xs' ? 'wrap center mobile' : 'column'}`}>
                    <a className='Footer__content__link' href='https://disneytermsofuse.com/' target='_blank' rel='noreferrer'>
                        <LinkText className='Footer__content__link__text' number={2}>Terms of Use</LinkText>
                    </a>
                    <a className='Footer__content__link' href='https://privacy.thewaltdisneycompany.com/en/' target='_blank' rel='noreferrer'>
                        <LinkText className='Footer__content__link__text' number={2}>Privacy Policy</LinkText>
                    </a>
                    <a className='Footer__content__link' href='https://privacy.thewaltdisneycompany.com/en/current-privacy-policy/your-us-state-privacy-rights/' target='_blank' rel='noreferrer'>
                        <LinkText className='Footer__content__link__text' number={2}>Your US State Privacy Rights</LinkText>
                    </a>
                    <a className='Footer__content__link' href='https://privacy.thewaltdisneycompany.com/en/for-parents/childrens-online-privacy-policy/' target='_blank' rel='noreferrer'>
                        <LinkText className='Footer__content__link__text' number={2}>Children's Online Privacy Policy</LinkText>
                    </a>
                    <a className='Footer__content__link' href='http://preferences-mgr.truste.com/?type=disneycolor&affiliateId=115' target='_blank' rel='noreferrer'>
                        <LinkText className='Footer__content__link__text' number={2}>Interest-Based Ads</LinkText>
                    </a>
                    <div className='Footer__content__link' rel='noreferrer'>
                        <button id='ot-sdk-btn' className='ot-sdk-show-settings'>Do Not Sell or Share My Personal Information</button>
                    </div>
                </div>
            </div>
        </Element>
    );
};

export default Footer;
