import React, { Fragment, useContext } from 'react';
import { Link } from 'react-scroll';
import { WidthContext } from '../../contexts/WidthContextProvider';
import { Heading, Subtitle } from '../modules/Typography';
import { Row } from '../modules/Grid';
import logo from '../../images/logo-white.png';

const TopNav = props => {
    const { isMobile, size } = useContext(WidthContext);

    let Element = Heading;
    let number = 5;
    if (size === 'md') {
        number = 6;
    } else if (isMobile) {
        Element = Subtitle;
        number = 2;
    }

    return (
        <div className='TopNav flex column center visible'>
            <Row className='TopNav__header flex align-flex-end'>
                <Link to='top' smooth duration={500} className='TopNav__left button'>
                    <img className='TopNav__logo' src={logo} alt='StudioLAB' />
                </Link>
                <div className='TopNav__right flex'>
                    <Fragment>
                        <Link to='about' smooth duration={500} className='TopNav__link button'>
                            <Element className='TopNav__text' number={number}>About</Element>
                        </Link>
                        <Link to='news' smooth duration={500} className='TopNav__link button'>
                            <Element className='TopNav__text' number={number}>News</Element>
                        </Link>
                        <Link to='partners' smooth duration={500} className='TopNav__link button'>
                            <Element className='TopNav__text' number={number}>Partners</Element>
                        </Link>
                        <Link to='contact' smooth duration={500} className='TopNav__link button'>
                            <Element className='TopNav__text' number={number}>Contact</Element>
                        </Link>
                    </Fragment>
                </div>
            </Row>
        </div>
    );
};

export default TopNav;
