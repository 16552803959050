import React, { useRef, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { Heading, Overline } from '../modules/Typography';
import { WidthContext } from '../../contexts/WidthContextProvider';
import rightArrow from '../../images/arrow-right.png';
import leftArrow from '../../images/arrow-left.png';

const Carousel = ({ articles }) => {
    const { isLarge, documentWidth, isMobile } = useContext(WidthContext);
    const sliderRef = useRef(null);
    const slides = isMobile ? 1 : 2;
    const settings = {
        infinite: true,
        autoplay: false,
        arrows: false,
        dots: false,
        slidesToShow: slides,
        slidesToScroll: slides
    };
    const [width, setWidth] = useState(documentWidth);

    useEffect(() => {
        setWidth(documentWidth);
    }, [documentWidth]);

    const handlePrevious = () => {
        sliderRef.current.slickPrev();
    };

    const handleNext = () => {
        sliderRef.current.slickNext();
    };

    return (
        <div className='NewsTile__slider__container'>
            <img className='NewsTile__slider__arrow button left' src={leftArrow} onClick={handlePrevious} />
            <div key={`${width}-${articles.length}`}>
                <Slider {...settings} className='NewsTile__slider' ref={sliderRef}>
                    {articles.map((article, idx) => (
                        <div data-index={+idx} className='NewsTile__slide flex column align-flex-start' key={`article-${idx}`}>
                            <div className='NewsTile__image__container flex column center'>
                                <img className='NewsTile__image' src={article.attributes.image.data.attributes.url} />
                            </div>
                            <Heading className='NewsTile__text' number={isLarge ? 4 : 6}>{article.attributes.headline}</Heading>
                            <a href={article.attributes.url} target='_blank' rel='noreferrer'>
                                <Overline className='NewsTile__link button'>READ MORE</Overline>
                            </a>
                        </div>
                    ))}
                </Slider>
            </div>
            <img className='NewsTile__slider__arrow button right' src={rightArrow} onClick={handleNext} />
        </div>
    );
};

Carousel.propTypes = {
    articles: PropTypes.array
};

export default Carousel;
