import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import '../styles/main.scss';
import WidthContextProvider from '../contexts/WidthContextProvider';

//import GraphiqlEditor from './GraphiqlEditor';
import Home from './pages/Home';
import Layout from './layout/Layout';


function App () {
    return (
        <WidthContextProvider>
            <BrowserRouter>
                <Layout>
                    <Routes>
                        <Route path='/' element={<Home />} />
                        <Route path='*' element={<Home />} />
                    </Routes>
                </Layout>
            </BrowserRouter>
        </WidthContextProvider>
    );
}

export default App;
